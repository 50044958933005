@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  row-gap: 20px;

  &__header {
    text-transform: uppercase;
    text-align: center;
  }

  &__logos {
    display: flex;
    column-gap: 40px;
    row-gap: 32px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.logo {
  position: relative;
  width: 190px;
  height: 30px;

  @include media(sm) {
    width: 236px;
    height: 42px;
  }
}
