@import '../../styles/variables';
@import '../../styles/mixin';

.container {
  margin: 60px 0 0;

  .content {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 15px;
    row-gap: 20px;
    width: 100%;

    @include media(sm) {
      padding: 64px 40px;
    }

    @each $size, $i in $breakpoints {
      @include media($size) {
        max-width: $i;
      }
    }

    .logos {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      justify-content: center;
      justify-items: center;
      padding-top: 10px;
      row-gap: 30px;
      width: 100%;

      @include media(xl) {
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        margin: auto;
        max-width: 900px;
      }

      &__logo {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        &__title {
          font-weight: 700;
        }

        > figure {
          height: auto;
          max-width: var(--icons-width);
          width: 100%;
        }

        p {
          line-height: 20px;
          max-width: 300px;
          order: 1;
        }
      }
    }

    p {
      max-width: 900px;
      line-height: 20px;
    }
  }
}
